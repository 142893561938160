import React, { useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FormPart1 from "../pages/IndividualPartner/FormPart1";
import FormPart2 from "../pages/IndividualPartner/FormPart2";
import FormPart3 from "../pages/IndividualPartner/FormPart3";

import registerPic from "../assets/images/Individual registration.png";
import kycPic from "../assets/images/Kyc.png";
import bankPic from "../assets/images/Bank Details.png";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/header.css";
import "../assets/css/signup.css";
import NavBar1 from "../components/Navbar";
import { baseUrl } from "../utils/baseUrl";

const Register = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    registerType: "individual",
    name: "",
    email: "",
    dob: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    Address: "",
    state: "",
    city: "",
    pincode: "",
    aadharNo: "",
    panNo: "",
    accHoldername: "",
    accNo: "",
    confirmaccNo: "",
    Ifsccode: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/partner_registeration`,
        formData
      );
      console.log("Form submitted", response.data.data);
      if (response && response.status === 200) {
        if (response.data.error === false) {
          const userData = response.data.data.userName;
          Swal.fire({
            text: "Your login details will be sent on your registered Email",
            title: `Your Partner code is "${userData}"`,
            icon: "success",
          });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message || error.response.data.err,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        toast.error("Error submitting form. Please try again later.");
      }
    }
  };

  return (
    <>
      <NavBar1 />
      <Container fluid className="otp-form-container">
        <Row className="d-flex align-items-center justify-content-center vh-100">
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            className="text-center bg-light-primary full-height"
          >
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              pause={false}
              className="mt-xl-10 mt-lg-5 mt-md-3"
            >
              <Carousel.Item>
                {step === 1 && (
                  <img
                    className="d-block w-100 img-height"
                    src={registerPic}
                    alt="First slide"
                    loading="lazy"
                    style={{ marginTop: "10rem" }}
                  />
                )}
                {step === 2 && (
                  <img
                    className="d-block w-100 img-height"
                    src={kycPic}
                    alt="First slide"
                    loading="lazy"
                    style={{ marginTop: "10rem" }}
                  />
                )}
                {step === 3 && (
                  <img
                    className="d-block w-100 img-height"
                    src={bankPic}
                    alt="First slide"
                    loading="lazy"
                    style={{ marginTop: "10rem" }}
                  />
                )}
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xl={7} lg={7} md={12} sm={12}>
            <div className="">
              {step === 1 && (
                <FormPart1
                  formData={formData}
                  handleChange={handleChange}
                  nextStep={nextStep}
                />
              )}
              {step === 2 && (
                <FormPart2
                  formData={formData}
                  handleChange={handleChange}
                  nextStep={nextStep}
                  prevStep={prevStep}
                />
              )}
              {step === 3 && (
                <FormPart3
                  formData={formData}
                  handleChange={handleChange}
                  prevStep={prevStep}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Register;

////////////////////////////////////////////////////////////

// import React, { useState } from "react";
// import { Container, Row, Col, Carousel } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// import FormPart1 from "../pages/IndividualPartner/FormPart1";
// import FormPart2 from "../pages/IndividualPartner/FormPart2";
// import FormPart3 from "../pages/IndividualPartner/FormPart3";

// import registerPic from "../assets/images/Individual registration.png";
// import kycPic from "../assets/images/Kyc.png";
// import bankPic from "../assets/images/Bank Details.png";
// import "bootstrap/dist/css/bootstrap.css";
// import "../assets/css/header.css";
// import "../assets/css/signup.css";
// import NavBar1 from "../components/Navbar";
// import { baseUrl } from "../utils/baseUrl";

// const Register = () => {
//   const [step, setStep] = useState(1);
//   const [formData, setFormData] = useState({
//     registerType: "individual",
//     name: "",
//     email: "",
//     dob: "",
//     mobileNo: "",
//     password: "",
//     confirmPassword: "",
//     Address: "",
//     state: "",
//     city: "",
//     pincode: "",
//     aadharNo: "",
//     panNo: "",
//     accHoldername: "",
//     accNo: "",
//     confirmaccNo: "",
//     Ifsccode: "",
//   });

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const nextStep = () => {
//     setStep(step + 1);
//   };

//   const prevStep = () => {
//     setStep(step - 1);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(formData);
//     try {
//       const response = await axios.post(
//         `${baseUrl}/partner_registeration`,
//         formData
//       );
//       console.log("Form submitted", response.data);
//       navigate("/login");
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   return (
//     <>
//       <NavBar1 />
//       <Container fluid className="otp-form-container">
//         <Row className="d-flex align-items-center justify-content-center vh-100">
//           <Col
//             xl={5}
//             lg={5}
//             md={12}
//             sm={12}
//             className="text-center bg-light-primary full-height"
//           >
//             <Carousel
//               controls={false}
//               indicators={false}
//               interval={3000}
//               pause={false}
//               className="mt-xl-10 mt-lg-5 mt-md-3"
//             >
//               <Carousel.Item>
//                 {step === 1 && (
//                   <img
//                     className="d-block w-100 img-height"
//                     src={registerPic}
//                     alt="First slide"
//                     style={{ marginTop: "10rem" }}
//                   />
//                 )}
//                 {step === 2 && (
//                   <img
//                     className="d-block w-100 img-height"
//                     src={kycPic}
//                     alt="First slide"
//                     style={{ marginTop: "10rem" }}
//                   />
//                 )}
//                 {step === 3 && (
//                   <img
//                     className="d-block w-100 img-height"
//                     src={bankPic}
//                     alt="First slide"
//                     style={{ marginTop: "10rem" }}
//                   />
//                 )}
//               </Carousel.Item>
//             </Carousel>
//           </Col>
//           <Col xl={7} lg={7} md={12} sm={12}>
//             <div className="">
//               {step === 1 && (
//                 <FormPart1
//                   formData={formData}
//                   handleChange={handleChange}
//                   nextStep={nextStep}
//                 />
//               )}
//               {step === 2 && (
//                 <FormPart2
//                   formData={formData}
//                   handleChange={handleChange}
//                   nextStep={nextStep}
//                   prevStep={prevStep}
//                 />
//               )}
//               {step === 3 && (
//                 <FormPart3
//                   formData={formData}
//                   handleChange={handleChange}
//                   prevStep={prevStep}
//                   handleSubmit={handleSubmit}
//                 />
//               )}
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default Register;

// import React, { useEffect, useState } from "react";
// import { Container, Row, Col, Carousel } from "react-bootstrap";
// import { Navbar, Nav, Dropdown } from "react-bootstrap";
// import Logo from "../assets/images/Batuk Logo.png";
// import "../assets/css/header.css";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import "bootstrap/dist/css/bootstrap.css";
// import "../assets/css/signup.css";
// import FormPart1 from "../pages/IndividualPartner/FormPart1";
// import FormPart2 from "../pages/IndividualPartner/FormPart2";
// import FormPart3 from "../pages/IndividualPartner/FormPart3";
// import Ci from "../assets/images/Curated Icons.png";
// import Cd from "../assets/images/Completely digitalised.png";
// import Bs from "../assets/images/Bank Grade Security.png";
// import Hc from "../assets/images/Help Center.png";
// import image1 from "../assets/images/1.png";
// import image2 from "../assets/images/2.png";
// import image3 from "../assets/images/3.png";
// const Register = () => {
//   const [step, setStep] = useState(1);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     dob: "",
//     phone: "",
//     password: "",
//     confirmPassword: "",
//     address: "",
//     city: "",
//     state: "",
//     pincode: "",
//     aadhar: "",
//     pan: "",
//     accountHolderName: "",
//     accountNo: "",
//     confirmAccountNo: "",
//     ifscCode: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const nextStep = () => {
//     setStep(step + 1);
//   };

//   const prevStep = () => {
//     setStep(step - 1);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted", formData);
//     // Here you can handle form submission (e.g., send the data to a server)
//   };

//   return (
//     <>
//       <Navbar expand="lg" className="header-navbar">
//         <Navbar.Brand
//           href="/"
//           className="header-brand"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img
//             src={Logo}
//             width="200"
//             height="auto"
//             className="d-inline-block align-top"
//             alt="LOGO"
//           />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse
//           id="basic-navbar-nav"
//           style={{ justifyContent: "center" }}
//         >
//           <Nav className="ml-auto">
//             <Nav.Link href="#about" className="header-link">
//               About
//             </Nav.Link>
//             <Nav.Link href="#blogs" className="header-link">
//               Blogs
//             </Nav.Link>
//             <Nav.Link href="#faq" className="header-link">
//               FAQ
//             </Nav.Link>
//             <Nav.Link href="#insights" className="header-link">
//               Insights
//             </Nav.Link>
//             <Nav.Link href="#contact" className="header-link">
//               Contact us
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//         <div className="reg-log">
//           <Nav.Link href="/login" className="header-login">
//             Login
//           </Nav.Link>
//           <Dropdown>
//             <Dropdown.Toggle
//               variant="link"
//               id="dropdown-basic"
//               className="header-register"
//               style={{ textDecoration: "none" }}
//             >
//               Register
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="dropdown-menu-custom">
//               <Dropdown.Item href="/register">Individual Partner</Dropdown.Item>
//               <Dropdown.Item href="/indRegister">Entity Partner</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       </Navbar>
//       <Container fluid className="otp-form-container">
//         <Row className="d-flex align-items-center justify-content-center vh-100">
//           <Col
//             xl={6}
//             lg={6}
//             md={12}
//             sm={12}
//             className="text-center bg-light-primary full-height"
//           >
//             <Carousel
//               controls={false}
//               indicators={false}
//               interval={3000}
//               pause={false}
//               className="mt-xl-10 mt-lg-5 mt-md-3"
//             >
//               <Carousel.Item>
//                 <img
//                   className="d-block w-100 img-height"
//                   src={image1}
//                   alt="First slide"
//                   style={{ marginTop: "10rem" }}
//                 />
//               </Carousel.Item>
//               <Carousel.Item>
//                 <img
//                   className="d-block w-100 img-height"
//                   src={image2}
//                   alt="Second slide"
//                   style={{ marginTop: "10rem" }}
//                 />
//               </Carousel.Item>
//               <Carousel.Item>
//                 <img
//                   className="d-block w-100 img-height"
//                   src={image3}
//                   alt="Third slide"
//                   style={{ marginTop: "10rem" }}
//                 />
//               </Carousel.Item>
//             </Carousel>
//           </Col>
//           <Col xl={6} lg={6} md={12} sm={12}>
//             <div className="">
//               {step === 1 && (
//                 <FormPart1
//                   formData={formData}
//                   handleChange={handleChange}
//                   nextStep={nextStep}
//                 />
//               )}
//               {step === 2 && (
//                 <FormPart2
//                   formData={formData}
//                   handleChange={handleChange}
//                   nextStep={nextStep}
//                   prevStep={prevStep}
//                 />
//               )}
//               {step === 3 && (
//                 <FormPart3
//                   formData={formData}
//                   handleChange={handleChange}
//                   prevStep={prevStep}
//                   handleSubmit={handleSubmit}
//                 />
//               )}
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default Register;

// import React, { useState, useEffect } from "react";
// import { Form, Button, Row, Col, Tab, Tabs, Card } from "react-bootstrap";
// import { useForm, Controller } from "react-hook-form";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Swal from 'sweetalert2'
// // import Loading from "../component/Loading";
// // import ErrorMessage from "../component/ErrorMessage";

// import { useNavigate } from "react-router-dom";
// import {
//   CitySelect,
//   CountrySelect,
//   StateSelect,
// } from "@davzon/react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import {
//   // Button,
//   // Row,
//   // Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   Modal,
// } from "react-bootstrap";

// import BatukLogo from "../images/Batuk-logo.png";

// const Register = () => {
//   const navigate = useNavigate();

//   const [selectedEntityType, setSelectedEntityType] = useState("");
//   const [label, setLabel] = useState("Company Type Incorporation no.");

//   // Function to handle changes in the dropdown selection
//   const handleEntityTypeChange = (e) => {
//     const selectedEntityType = e.target.value;
//     // Update the label based on the selected entity type
//     const newLabel = getLabel(selectedEntityType);
//     // Set the label dynamically
//     setLabel(newLabel);
//   };

//   const [countryid, setCountryid] = useState(0);
//   const [stateid, setstateid] = useState(0);
//   const [cityId, setCityId] = useState("");

//   // const country = stateList[e.target.value]; // here you will get full country object.
//   // setCountryid(country.id);

//   // const [values, setValues] = useState({
//   //     entityType: "",
//   //     entityName: "",
//   //     corpNo: "",
//   //     entityEmail: "",
//   //     password: "",
//   //     confirmPass: "",
//   //     panNo: "",
//   //     gistin: "",
//   //     name: "",
//   //     email: "",
//   //     contact: "",
//   //     designation: ""
//   // });

//   const {
//     register,
//     handleSubmit,
//     watch,
//     control,
//     formState: { errors },
//   } = useForm();

//   // Function to get the label based on the selected entity type
//   const getLabel = (selectedEntityType) => {
//     switch (selectedEntityType) {
//       case "PivL":
//         return "Individual Company Incorporation no.";
//       case "PubL":
//         return "Sole Proprietorship Limited Company Incorporation no.";
//       case "Ltd":
//         return "Partnership Company Incorporation no.";
//       case "LLP":
//         return "Private Limited  Incorporation no.";
//       case "Trust":
//         return "Public Limited Incorporation no.";
//       case "Partnership":
//         return "LLP (Limited Liability Partnership) Incorporation no.";
//       case "OPC":
//         return "Trust Incorporation no.";
//       case "OPC":
//         return "Society Incorporation no.";
//       case "OPC":
//         return "Government Incorporation no.";
//       case "OPC":
//         return "Hindu Undivided Famliy Incorporation no.";
//       case "OPC":
//         return "Local Authority Incorporation no.";
//       case "OPC":
//         return "NGO Incorporation no.";
//       case "OPC":
//         return "One Person Comppany Incorporation no.";
//       case "OPC":
//         return "Artificial Juridical Person";
//       default:
//         return "Company Type Incorporation no.";
//     }
//   };

//   const registerUser = async (userData) => {
//     try {
//       const requestBody = {
//         registerType: "entity",
//         EntityType: userData.entitytype,
//         Entityname: userData.entityname,
//         Entityemail: userData.entitymail,
//         companyAddress: userData.address,
//         EntityidentificationNo: userData.incorpno,
//         accHoldername: userData.accHolName,

//         accNo: userData.accNo,
//         confirmaccNo: userData.accNo,
//         authpersonName: userData.name,
//         Ifsccode: userData.ifsc,
//         Gstin: userData.gstin,
//         panNo: userData.pan,
//         mobileNo: userData.mobile,
//         password: userData.password,
//         confirmPassword: userData.confirmpassword,
//         city: userData.city.name,
//         state: userData.state.name,
//       };
//       console.log("city", userData.city.name);
//       console.log("body", requestBody);
//       const response = await axios.post(
//         `${baseUrl}/partner_registeration",
//         requestBody
//       );

//       console.log(response);
//       if (response.status == 200) {
//         // Optionally log the response
//         // Redirect or handle successful registration here
//         Swal.fire({
//           title: "Thank You!",
//           text: "Registered Successfully!",
//           icon: "success"
//         });
//         navigate("/login");
//       }// Assuming you're using react-router-dom
//     } catch (error) {
//       console.error("Error registering user:", error);
//       // Handle error (display message, etc.)
//     }
//   };

//   // Inside the Register component

//   const registerSubmit = (data) => {
//     console.log("registerData", data.entityname);
//     registerUser(data); // Call the registerUser function with form data
//   };

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setstateid("");
//     setCityId("");
//   };

//   const handleStateChange = (state) => {
//     setstateid(state.id);
//     setCityId("");
//   };

//   const handleCityChange = (city) => {
//     setCityId(city.id);
//   };

//   // ============================================================

//   const token = sessionStorage.getItem("token");

//   useEffect(() => {
//     if (token) {
//       handleShow();
//     }
//   }, []);

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   return (
//     // <div className="container-fluid">
//     //   <div className="container mt-5">
//     //     <div className="card p-5 shadow">
//     //       <div className="col text-center">
//     //         <p> Register with us & be the part of great oppertunity.</p>
//     //       </div>
//     //       <div className="container mt-5">
//     //         <div className="row">
//     //           <div className="col-md-7 mx-auto">
//     //             <Form onSubmit={handleSubmit(registerSubmit)} className=" mt-4">
//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Entity Type</Form.Label>

//     //                 <Form.Select
//     //                   onChange={handleEntityTypeChange}
//     //                   {...register("entitytype", { required: true })}>
//     //                   <option>Select</option>
//     //                   <option value="PivL">Individual Company </option>
//     //                   <option value="PubL">Sole Proprietorship</option>
//     //                   <option value="PubL">Partnership</option>
//     //                   <option value="LLP">Private Limited</option>
//     //                   <option value="Trust">Public Limited</option>
//     //                   <option value="Partnership">
//     //                     LLP (Limited Liability Partnership)
//     //                   </option>
//     //                   <option value="OPC">Trust</option>
//     //                   <option value="OPC">Society</option>
//     //                   <option value="OPC">Government</option>
//     //                   <option value="OPC">Hindu Undivided Famliy</option>
//     //                   <option value="OPC">Local Authority</option>
//     //                   <option value="OPC">NGO</option>
//     //                   <option value="OPC">One Person Comppany</option>
//     //                   <option value="OPC">Artificial Juridical Person</option>
//     //                 </Form.Select>
//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.entitytype?.type === "required" &&
//     //                     "Select your entity type"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Company Name / Entity Name</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   {...register("entityname", { required: true })}
//     //                   placeholder="Entity Name"
//     //                 />
//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.entityname?.type === "required" &&
//     //                     "Entity name is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>{label}</Form.Label>
//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Incoporation No."
//     //                   {...register("incorpno", { required: true })}
//     //                 />
//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.incorpno?.type === "required" &&
//     //                     "Incorporation no is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Entity Email</Form.Label>

//     //                 <Form.Control
//     //                   type="email"
//     //                   placeholder="Entity Email"
//     //                   {...register("entitymail", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.entitymail?.type === "required" &&
//     //                     "Entity email is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Password</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter your password"
//     //                   {...register("password", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.password?.type === "required" &&
//     //                     "Password is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Confirm Password</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Confirm password"
//     //                   {...register("confirmpassword", {
//     //                     required: true,
//     //                     validate: (val) => {
//     //                       if (watch("password") != val) {
//     //                         return "Your passwords do no match";
//     //                       }
//     //                     },
//     //                   })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.confirmpassword?.type === "required" &&
//     //                     "Confirm password is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>PAN no.</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter PAN"
//     //                   {...register("pan", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.pan?.type === "required" && "PAN no is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>GSTIN (optional)</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter your GSTIN"
//     //                   {...register("gstin")}
//     //                 />
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label> Authorized Person Name</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter Full Name"
//     //                   {...register("name", { required: true })}
//     //                 />
//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.name?.type === "required" &&
//     //                     "Enter full name required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Mobile no</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter PAN"
//     //                   {...register("mobile", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.mobile?.type === "required" &&
//     //                     "mobile no is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Company Address</Form.Label>

//     //                 <Form.Control
//     //                   as="textarea"
//     //                   placeholder="Enter Full Address"
//     //                   {...register("address", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.address?.type === "required" &&
//     //                     "Address is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Country</Form.Label>
//     //                 <Controller
//     //                   name="country"
//     //                   control={control}
//     //                   rules={{ required: true }}
//     //                   render={({ field }) => (
//     //                     <CountrySelect
//     //                       {...field}
//     //                       onChange={(country) => {
//     //                         field.onChange(country);
//     //                         handleCountryChange(country);
//     //                       }}
//     //                       placeHolder="Select Country"
//     //                     />
//     //                   )}
//     //                 />
//     //                 {errors.country && (
//     //                   <span
//     //                     className="text-danger"
//     //                     style={{ fontSize: "12px" }}>
//     //                     Country is required
//     //                   </span>
//     //                 )}
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>State</Form.Label>
//     //                 <Controller
//     //                   name="state"
//     //                   control={control}
//     //                   rules={{ required: true }}
//     //                   render={({ field }) => (
//     //                     <StateSelect
//     //                       countryid={countryid}
//     //                       {...field}
//     //                       onChange={(state) => {
//     //                         field.onChange(state);
//     //                         handleStateChange(state);
//     //                       }}
//     //                       placeHolder="Select State"
//     //                     />
//     //                   )}
//     //                 />
//     //                 {errors.state && (
//     //                   <span
//     //                     className="text-danger"
//     //                     style={{ fontSize: "12px" }}>
//     //                     State is required
//     //                   </span>
//     //                 )}
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>City</Form.Label>
//     //                 <Controller
//     //                   name="city"
//     //                   control={control}
//     //                   rules={{ required: true }}
//     //                   render={({ field }) => (
//     //                     <CitySelect
//     //                       countryid={countryid}
//     //                       stateid={stateid}
//     //                       {...field}
//     //                       onChange={(city) => {
//     //                         field.onChange(city);
//     //                         handleCityChange(city);
//     //                       }}
//     //                       placeHolder="Select City"
//     //                     />
//     //                   )}
//     //                 />
//     //                 {errors.city && (
//     //                   <span
//     //                     className="text-danger"
//     //                     style={{ fontSize: "12px" }}>
//     //                     City is required
//     //                   </span>
//     //                 )}
//     //               </Form.Group>

//     //               <h6>Bank Details</h6>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label> Account Holder's Name</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter Account Holder Name"
//     //                   {...register("accHolName", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.accHolName?.type === "required" &&
//     //                     "Account holder name is required"}
//     //                 </span>
//     //               </Form.Group>
//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Account No.</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter Account No"
//     //                   {...register("accNo", { required: true })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.accNo?.type === "required" &&
//     //                     "Account no is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>Confirm Account No.</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Confirm Account"
//     //                   {...register("accNo", {
//     //                     required: true,
//     //                     validate: (val) => {
//     //                       if (watch("accNo") != val) {
//     //                         return "Your account no does not match";
//     //                       }
//     //                     },
//     //                   })}
//     //                 />

//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.conAccNo?.type === "required" &&
//     //                     "Confirm account is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <Form.Group className="mb-2">
//     //                 <Form.Label>IFSC Code</Form.Label>

//     //                 <Form.Control
//     //                   type="text"
//     //                   placeholder="Enter Bank IFSC Code"
//     //                   {...register("ifsc", { required: true })}
//     //                 />
//     //                 <span className="text-danger" style={{ fontSize: "12px" }}>
//     //                   {errors.ifsc?.type === "required" &&
//     //                     "IFSC code is required"}
//     //                 </span>
//     //               </Form.Group>

//     //               <div className="d-grid gap-2 mt-3">
//     //                 <button
//     //                   className="btn text-light fw-bold"
//     //                   style={{ backgroundColor: "#10A37F" }}>
//     //                   Register
//     //                 </button>
//     //               </div>
//     //             </Form>
//     //           </div>
//     //         </div>
//     //       </div>
//     //     </div>
//     //   </div>
//     // </div>

//     // =========================================================================================================

//     <div
//       className="container-fluid body w-100 pt-5 pb-5 bg-white"
//       style={{
//         height: "100vh",
//       }}>
//       <Row className="d-flex justify-content-center">
//         <Col md={6} lg={6} xl={6} className="pt-2">
//           <Card
//             className="rounded"
//             style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.2)" }}>
//             <CardHeader
//               className="text-center p-3 "
//               style={{ backgroundColor: "#4a2a7d", color: "white" }}>
//               <Col>
//                 <img src={BatukLogo} alt="Logo" width={100} />
//               </Col>
//             Batuk Partner
//             </CardHeader>
//             <CardBody className="w-100" width={100}>
//               <div className="p-4 mx-auto">
//               <h4 className="bank_details_header_login">Register as
//             Entity Partner</h4>
//                 <Form onSubmit={handleSubmit(registerSubmit)} className=" mt-4">

//                   <div className="row">
//                     <div className="col-sm-6">
//                       <Form.Group className="mb-2">
//                         <Form.Label>Entity Type</Form.Label>

//                         <Form.Select
//                           onChange={handleEntityTypeChange}
//                           {...register("entitytype", { required: true })}>
//                           <option>Select</option>
//                           <option value="Individual Company">Individual Company </option>
//                           <option value="Sole Proprietorship">Sole Proprietorship</option>
//                           <option value="Partnership">Partnership</option>
//                           <option value="Private Limited">Private Limited</option>
//                           <option value="Public Limited">Public Limited</option>
//                           <option value="LLP">
//                             LLP (Limited Liability Partnership)
//                           </option>
//                           <option value="Trust">Trust</option>
//                           <option value="Society">Society</option>
//                           <option value="Government">Government</option>
//                           <option value="Hindu Undivided Famliy">Hindu Undivided Famliy</option>
//                           <option value="Local Authority">Local Authority</option>
//                           <option value="NGO">NGO</option>
//                           <option value="One Person Comppany">One Person Comppany</option>
//                           <option value="Artificial Juridical Person">
//                             Artificial Juridical Person
//                           </option>
//                         </Form.Select>
//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.entitytype?.type === "required" &&
//                             "Select your entity type"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       <Form.Group className="mb-2">
//                         <Form.Label>Company Name / Entity Name</Form.Label>

//                         <Form.Control
//                           type="text"
//                           {...register("entityname", { required: true })}
//                           placeholder="Entity Name"
//                         />
//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.entityname?.type === "required" &&
//                             "Entity name is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>{label}</Form.Label>
//                         <Form.Control
//                           type="text"
//                           placeholder="Incoporation No."
//                           {...register("incorpno", { required: true })}
//                         />
//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.incorpno?.type === "required" &&
//                             "Incorporation no is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>Entity Email</Form.Label>

//                         <Form.Control
//                           type="email"
//                           placeholder="Entity Email"
//                           {...register("entitymail", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.entitymail?.type === "required" &&
//                             "Entity email is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>Password</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter your password"
//                           {...register("password", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.password?.type === "required" &&
//                             "Password is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>Confirm Password</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Confirm password"
//                           {...register("confirmpassword", {
//                             required: true,
//                             validate: (val) => {
//                               if (watch("password") != val) {
//                                 return "Your passwords do no match";
//                               }
//                             },
//                           })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.confirmpassword?.type === "required" &&
//                             "Confirm password is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>PAN no.</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter PAN"
//                           {...register("pan", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.pan?.type === "required" &&
//                             "PAN no is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>GSTIN (optional)</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter your GSTIN"
//                           {...register("gstin")}
//                         />
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label> Authorized Person Name</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter Full Name"
//                           {...register("name", { required: true })}
//                         />
//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.name?.type === "required" &&
//                             "Enter full name required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       <Form.Group className="mb-2">
//                         <Form.Label>Mobile no</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter PAN"
//                           {...register("mobile", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.mobile?.type === "required" &&
//                             "mobile no is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">
//                     <Form.Group className="mb-2">
//                         <Form.Label>Country</Form.Label>
//                         <Controller
//                           name="country"
//                           control={control}
//                           rules={{ required: true }}
//                           render={({ field }) => (
//                             <CountrySelect
//                               style={{ border: "0px solid white" }}
//                               {...field}
//                               onChange={(country) => {
//                                 field.onChange(country);
//                                 handleCountryChange(country);
//                               }}
//                               placeHolder="Select Country"
//                             />
//                           )}
//                         />
//                         {errors.country && (
//                           <span
//                             className="text-danger"
//                             style={{ fontSize: "12px" }}>
//                             Country is required
//                           </span>
//                         )}
//                       </Form.Group>

//                     </div>
//                     <div className="col-sm-6">
//                       {" "}

//                       <Form.Group className="mb-2">
//                         <Form.Label>State</Form.Label>
//                         <Controller
//                           name="state"
//                           control={control}
//                           rules={{ required: true }}
//                           render={({ field }) => (
//                             <StateSelect
//                               countryid={countryid}
//                               {...field}
//                               onChange={(state) => {
//                                 field.onChange(state);
//                                 handleStateChange(state);
//                               }}
//                               placeHolder="Select State"
//                             />
//                           )}
//                         />
//                         {errors.state && (
//                           <span
//                             className="text-danger"
//                             style={{ fontSize: "12px" }}>
//                             State is required
//                           </span>
//                         )}
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">

//                       {/* =======================  address company================================= */}

//                       <Form.Group className="mb-2">
//                         <Form.Label>City</Form.Label>
//                         <Controller
//                           name="city"
//                           control={control}
//                           rules={{ required: true }}
//                           render={({ field }) => (
//                             <CitySelect
//                               countryid={countryid}
//                               stateid={stateid}
//                               {...field}
//                               onChange={(city) => {
//                                 field.onChange(city);
//                                 handleCityChange(city);
//                               }}
//                               placeHolder="Select City"
//                             />
//                           )}
//                         />
//                         {errors.city && (
//                           <span
//                             className="text-danger"
//                             style={{ fontSize: "12px" }}>
//                             City is required
//                           </span>
//                         )}
//                       </Form.Group>

//                       {/* ========================================================================== */}
//                       {" "}

//                     </div>
//                     <div className="col-sm-6">

//                     <Form.Group className="mb-2">
//                         <Form.Label>Company Address</Form.Label>

//                         <Form.Control
//                           as="textarea"
//                           placeholder="Enter Full Address"
//                           {...register("address", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.address?.type === "required" &&
//                             "Address is required"}
//                         </span>
//                       </Form.Group>

//                     </div>
//                   </div>
//                   <h2  className="bank_details_header">Bank Details</h2>
//                   <div className="row">
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label> Account Holder's Name</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter Account Holder Name"
//                           {...register("accHolName", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.accHolName?.type === "required" &&
//                             "Account holder name is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>Account No.</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter Account No"
//                           {...register("accNo", { required: true })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.accNo?.type === "required" &&
//                             "Account no is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-6">
//                       {" "}
//                       <Form.Group className="mb-2">
//                         <Form.Label>Confirm Account No.</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Confirm Account"
//                           {...register("accNo", {
//                             required: true,
//                             validate: (val) => {
//                               if (watch("accNo") != val) {
//                                 return "Your account no does not match";
//                               }
//                             },
//                           })}
//                         />

//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.conAccNo?.type === "required" &&
//                             "Confirm account is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                     <div className="col-sm-6">
//                       <Form.Group className="mb-2">
//                         <Form.Label>IFSC Code</Form.Label>

//                         <Form.Control
//                           type="text"
//                           placeholder="Enter Bank IFSC Code"
//                           {...register("ifsc", { required: true })}
//                         />
//                         <span
//                           className="text-danger"
//                           style={{ fontSize: "12px" }}>
//                           {errors.ifsc?.type === "required" &&
//                             "IFSC code is required"}
//                         </span>
//                       </Form.Group>
//                     </div>
//                   </div>

//                   <div className="d-grid gap-2 mt-3">
//                     <button
//                       className="btn text-light fw-bold"
//                       style={{
//                         backgroundColor: "rgb(74 42 125)",
//                         padding: "10px 0px",
//                         fontSize: "15px",
//                       }}>
//                       Register
//                     </button>
//                   </div>
//                 </Form>
//                 <Row className="py-3 text-center">
//                   <p
//                     style={{
//                       color: " #4a2a7d",
//                       fontSize: "15px",
//                       paddingBottom: "0px",
//                       fontWeight: "500",
//                       marginBottom: "12px",
//                     }}>
//                     Already have an account ? <Link to="/login">Login</Link>
//                   </p>

//                 </Row>
//               </div>
//             </CardBody>

//           </Card>
//         </Col>
//       </Row>
//     </div>

//     // =================================================================================================================
//   );
// };

// export default Register;
